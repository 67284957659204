import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import Caption from '../components/caption';
import NextProj from '../components/nextproject';




import Layout from '../components/layout'
import '../components/layout.css'
import '../components/projectCards.css'



const Steam = (props) => (
  <Layout>
      <Helmet
      title="Juan Herrera — Reimagining Steam concept"
      meta={[
        { name: 'description', content: 'Juan Herrera — Product designer' },
        { name: 'keywords', content: 'product, design, ui, ux' },
      ]}
      />
    <div className="casestudies">
      <div className="casestudyTop">
      <div className="back"><p><Link to="/">Back</Link></p></div>
        <div className="topcontainer">
          <div className="casestudyintro">
            <h2>Reimagining Steam</h2>
            <p>This is a rather large concept I was working on for three reasons: to push Android design in the (very iOS-centric) STRV design team; to help the company look for opportunities in fields not yet explored; and ultimately, to put my thoughts into paper as to what in my opinion could be better in the Steam mobile experience.</p>
          </div>
          <div id="CSinfo">
            <div className="projectInfo"><h5>Role</h5>
              <p>Concept, direction<br /></p>
              <h5>Platform</h5>
              <p>Desktop</p>
              <h5>Year</h5>
              <p>2017–2018</p>
            </div>
          </div>
        </div>
      </div>
        <div className="desktopImages">
              <Img alt="Steam Hero" fluid={props.data.steam1.childImageSharp.fluid} />
        </div>
        <Caption captiontext="Steam — Library (Android)"/>
      <div className="dividers">Overview</div>
        <div className="casestudyContent">
            <div className="CSleftCol">
              <p>This concept consists of two things. The most obvious, at a glance, is a refresh to the visual identity that aims to help Steam’s look evolve. Indeed, the intention wasn't to create an entirely new visual style, but to build upon what’s already there to create a more modern look for Valve’s platform.</p>
              </div>
            <div className="CSrightCol">
              <p>The second part of the concept is an overhaul of both the Android and iOS Steam apps, as well as a smaller design experiment with Steam’s Big Picture mode. The latter is primarily a means to translate the proposed design language to a different context rather than a proper redesign.</p>
            </div>
        </div>
            <div className="desktopImages">
              <Img alt="Steam Mobile" fluid={props.data.steam2.childImageSharp.fluid} />
           </div>
            <div className="desktopImages">
              <Img alt="Steam Mobile" fluid={props.data.steam4.childImageSharp.fluid} />
           </div>
           <div className="desktopImages">
              <Img alt="Steam Big Picture mode" fluid={props.data.steam3.childImageSharp.fluid} />
           </div>
           <Caption captiontext="Steam — Assorted Big Picture mode screens"/>
           <div className="casestudyContent">
            <div className="CSleftCol">
              <p>If you're interested in more examples specifically dealing with visual design, by all means look at the <a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/gallery/65439679/Reimagining-Steam">Behance project</a>.</p>
              </div>
            <div className="CSrightCol">
              <p>Conversely, if you're more interested in the whys and wherefores, you can read my lengthy <a target="_blank" rel="noopener noreferrer" href="https://medium.com/p/e46821ac59d3">Medium post</a> about this. It's at least mildly entertaining!</p>
            </div>
          </div>

          <NextProj
              nextproj='Feeln'
              projdesc='Family-friendly streaming.'
              nextlink='/feeln'
              />
          </div>
  </Layout>
)

export default Steam


// Image stuff

export const pageQuery = graphql`
  query {
    steam1: file(relativePath: { eq: "steam-01.png" }) {
      ...fluidImage
    }
    steam2: file(relativePath: { eq: "steam-02.png" }) {
      ...fluidImage
    }
    steam3: file(relativePath: { eq: "steam-03.jpg" }) {
      ...fluidImage
    }
    steam4: file(relativePath: { eq: "steam-04.png" }) {
      ...fluidImage
    }

  }
`
